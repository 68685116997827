// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faBars as farFaBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faTimes as farFaTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";

import { faPhone as fasFaPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";

import { faFacebook as fabFaFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";

/**
 * Add regular icons
 */
library.add(farFaBars, farFaTimes);

/**
 * Add solid icons
 */
library.add(fasFaPhone);

/**
 * Add brand icons
 */
library.add(fabFaFacebook);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
